p.dpsp-share-text {
    display: inline-block;
    font-weight: 400;
    background: #ebe7e2;
    padding: 10px;
    padding-left: 15px;
}

div#dpsp-content-bottom {
    width: 25%;
    background: #ebe7e2;
    display: inline-block;
    position: relative;
    top: 17px;
    @media screen and (max-width: 991px) {
        width: 30%;
    }
    li {
        padding: 7px 0;
        margin: 0;
        a {
            &::before {
                background: 0 !important;

            }
        }
    }
}

@media screen and (max-width: 991px) {
    .share_btn {
        position: relative;
        top: -78px;
    }
}

.dpsp-networks-btns-wrapper .dpsp-network-btn:after {
    display: block !important;
}

.dpsp-networks-btns-wrapper .dpsp-network-btn {
    height: 30px !important;

}

div#dpsp-content-bottom li a::before {
    background: 0!important;
    color: #cccac7;
    border: none !important;
}


a.dpsp-network-btn.dpsp-facebook.dpsp-no-label.dpsp-first {
    background: none !important;
    border: none !important;

    &:hover {
        &::before {
            background: 0!important;
            color: #4267B2 !important;
            border: none !important;
        }
    }
}

a.dpsp-network-btn.dpsp-twitter.dpsp-no-label {
    background: none !important;
    border: none !important;
    &:hover {
        &::before {
            background: 0!important;
            color: #41ABE1 !important;
            border: none !important;
        }
    }
}

a.dpsp-network-btn.dpsp-google-plus.dpsp-no-label.dpsp-last {
    background: none !important;
    border: none !important;
    &:hover {
        &::before {
            background: 0!important;
            color: #DD493D !important;
            border: none !important;
        }
    }
}
