.main_menu {
    position: fixed;
    z-index: 1000;
    left: 0;
    right: 0;
    .navbar-default {


        .navbar-toggle {
            border: 0;
            .icon-bar {
                background-color: #b29f71;
            }
        }
    }
    ul.nav.navbar-nav.navbar-right {
        margin: 0 0 0 40px!important;
    }
    .navbar-right {
        color: #fff;

        text-transform: uppercase;
        li {

            line-height: 70px;
            padding: 0 10px;
        }
    }

    .navbar-default {
        background: rgba(#231f20, 0.95);
        border: 0;
        .navbar-nav {
            .active {
                a {
                    color: #966e41;
                    background: 0 !important;
                }
            }

            li {
                &:hover {
                    a {
                        color: $secondColor !important;

                    }
                    .dropdown-menu > li > a {
                        color: #000 !important;
                    }
                    .dropdown-menu > li > a:focus,
                    .dropdown-menu > li > a:hover {
                        color: #fff !important;
                        @media screen and (max-width: 991px) {
                            color: $mainColor !important;
                        }
                        text-decoration: none;
                        background: 0;
                    }
                }
                @media only screen and (max-width: 991px) {
                    border-bottom: 1px solid rgba(196, 182, 125, 0.67);
                }
                a {
                    line-height: 30px;
                    text-transform: uppercase;
                    color: #fff;
                    padding: 30px 10px;
                    @media only screen and (max-width: 991px) {
                        color: #000;

                    }
                    @media screen and (max-width: 991px) {
                        padding: 5px !important;
                    }
                }
            }
            margin: 0 60px;
            @media only screen and (max-width: 1600px) {
                margin: 0 20px !important;
            }
        }
        .navbar-brand {
            padding: 0;
            img {
                max-width: 180px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .navbar-brand {
        img {
            max-width: 110px !important;
        }
    }
}


.navbar {
    border-radius: 0px;
}

.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
    background: 0;
}

@media only screen and (min-width: 992px) {
    .navbar .navbar-nav {
        display: inline-block;
        float: none;
        vertical-align: top;
    }
    .main_menu {
        .navbar-default {
            .navbar-brand {
                img {
                    position: absolute;
                }
            }
        }
    }
    .navbar .navbar-collapse {
        text-align: center;
    }
}

@media screen and (min-width: 992px) and (max-width: 1300px) {
    .main_menu .navbar-default .navbar-brand img {
        max-width: 130px;

    }
    .main_menu .navbar-default .navbar-nav li a {

        padding: 15px 8px !important;
        font-size: 11px;
    }
}


.right-navbar {
    .img-icon {
        max-width: 16px !important;
        padding-right: 5px;
    }
    position: absolute;
    right: 2%;
    top: 15px;
    z-index: 99;
    color: #fff;
    p {
        display: inline-block;
        color: #fff;
        padding: 0 10px;
    }
    .lang {
        background: $mainColor;

        line-height: 60px !important;
        margin: 0 15px !important;
        padding: 0 20px !important;
        @media screen and (min-width: 1200px) {
            &::after {
                content: '';
                position: absolute;
                border-left: 1px solid #fff;
                top: 0;
                bottom: 0;
                left: 82px;
            }
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1300px) {
    .right-navbar {
        position: absolute;
        right: 1%;
        top: 10px;
        z-index: 99;
        color: #fff;
        p {
            display: inline-block;
            color: #fff;
            padding: 0 !important;
            font-size: 12px;
        }
        .lang {
            background: $mainColor;

            line-height: 40px !important;
            margin: 0 5px !important;
            padding: 0 10px !important;
            &::after {
                content: '';
                position: absolute;
                border-left: 1px solid #fff;
                top: 0;
                bottom: 0;
                left: 55px;
            }

        }
    }
}

@media screen and (max-width: 991px) {
    .right-navbar {

        position: absolute;
        right: 70px;
        top: 5px;
        z-index: 99;
        color: #fff;
        p {
            display: inline-block;
            color: #fff;
            padding: 0 16px;
            font-size: 12px;
        }
        .lang {
            background: $mainColor;

            line-height: 40px !important;
            margin: 0 15px !important;
            padding: 0 10px !important;


        }
    }
}

@media screen and (max-width: 767px) {

    .lang {
        margin: 0 !important;

    }
}
