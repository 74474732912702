.video-embed {

    padding: 50px 0;
}

.vid-wrap {
    display: block;
    position: relative;
    h2 {
        color: #fff;
        text-align: center;
    }
    img {
        max-width: 100% !important;
        margin: 0 auto;
        width: 560px;
        cursor: pointer;
        position: relative;
        &.btn-play {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0.3;
            width: 50px;
        }
    }
    iframe {
        max-width: 100% !important;
        width: 560px;
        height: 315px;
        display: none;
    }
    a {
        display: none;
        .fa-times {
            background-color: #fff;
            padding: 3px 5px;
            border-radius: 50%;
            color: #000;
            position: absolute;
            font-size: 18px;
            top: 5px;
            right: 5px;
        }
    }
}
