.title-page {
    background-image: url(../images/bg-about.png);
    background-position: bottom;
    background-repeat: no-repeat;
    padding: 50px 0;
    @media screen and (max-width: 767px) {
        padding: 30px 0 !important;
    }
    border-bottom: 1px solid rgba(108, 71, 29, 0.21);
    .breadcrumb {
        margin: 0;
        background: 0;
        li {
            &:hover {
                a {
                    color: $mainColor;
                }
            }
            a {
                color: $secondColor;
            }
        }
    }
    h1 {
        @media screen and (max-width: 767px) {
            font-size: 30px;
        }
        font-weight: 400;
        text-transform: uppercase;
    }
}
section#facilities {
    padding: 50px 0;
    min-height: 600px;
    overflow-x: hidden;
    @media only screen and (max-width: 1199px) {
        height: inherit !important;
    }
    .fac-cover {
        margin: 50px 0 80px 0px;
    }
    .first-fac {
        .title-fac {
            img {
                margin: 10px auto;
                max-height: 30px;
            }
        }
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 500px;
        position: relative;
        margin: 20px 0;
        h3 {
            color: #fff !important;
            position: absolute;
            top: 40%;
            left: 0;
            right: 0;
            margin: 0 auto;
            text-align: center;
            opacity: 0;
            @include transition();
        }
        @include transition();
        &:hover {
            cursor: pointer;
            h3 {
                @include transition();
                opacity: 1;
                z-index: 9999;
            }
            &::before {
                @include transition();
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                display: block;
                content: '';
                width: 100%;
                height: 100%;
                z-index: 99;
                background: rgba($mainColor, 0.5);
            }
        }
    }
    .fac-content {
        h3 {
            margin: 10px 0 50px;
        }
        p {
            margin: 10px 0;
        }
        width: 59%;
        margin: 70px auto 0px;
        @media screen and (max-width: 991px) {
            width: 100%;
        }
    }
    .second-fac {
        .title-fac {
            img {
                margin: 10px auto;
                max-height: 30px;
            }
        }
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 540px;
        position: relative;
        h3 {
            color: #fff !important;
            position: absolute;
            top: 40%;
            left: 0;
            right: 0;
            margin: 0 auto;
            text-align: center;
            opacity: 0;
            @include transition();
        }
        @include transition();
        &:hover {
            cursor: pointer;
            h3 {
                @include transition();
                opacity: 1;
                z-index: 9999;
            }
            &::before {
                @include transition();
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                display: block;
                content: '';
                width: 100%;
                height: 100%;
                z-index: 99;
                background: rgba($mainColor, 0.5);
            }
        }
    }
    position: relative;
    overflow-y: hidden;
    h2 {
        font-family: 'Prata';
        color: #000;
        text-transform: uppercase;
        position: relative;
        &:after {
            content: '';
            display: inline-block;
            border-bottom: 2px solid #00a0df;
            width: 120px;
            left: 0;
            right: 0;
            bottom: -20px;
            margin: 0 auto;
            position: absolute;
        }
    }
    &:before {
        content: '';
        position: absolute;
        right: 0;
        z-index: 1;
        width: 278px;
        bottom: 0;
        height: 500px;
        @media only screen and (max-width: 991px) {
            transform: scale(.3);
            width: 278px;
            bottom: -180px;
            height: 500px;
            right: -100px;
        }
    }
    .all-facilities {
        .facilitie_mob {
            .cover {
                min-height: 400px;
                background-size: cover;
                position: relative;
                background-position: center;
                left: 0;
                top: 0;
                width: 100%;
                @media only screen and (max-width: 1199px) {
                    position: static !important;
                    width: 100%;
                }
            }
            .details {
                text-align: center;
                img {
                    margin: 30px auto -10px auto;
                    width: 20px !important;
                }
                span {
                    display: block;
                    text-transform: uppercase;
                    color: #000;
                    font-family: 'Montserrat';
                }
                h3 {
                    width: 70%;
                    @media only screen and (max-width: 767px) {
                        width: 100% !important;
                    }
                    margin: 0 auto;
                    font-size: 20px;
                    color: #000;
                    font-weight: 400;
                    padding: 40px 0 15px 0;
                }
                p {
                    margin-bottom: 50px;
                }
            }
        }
        .owl-carousel {
            margin-top: 100px;
            @media only screen and (max-width: 767px) {
                margin-top: 40px !important;
            }
            .owl-stage-outer {
                min-height: 600px;
                .owl-item {
                    .cover {
                        min-height: 600px;
                        background-size: cover;
                        position: relative;
                        left: 0;
                        top: 0;
                        width: 100%;
                        @media only screen and (max-width: 1199px) {
                            position: static !important;
                            width: 100%;
                        }
                    }
                }
                width: auto;
                margin: 0 auto;
            }
            .details {
                text-align: center;
                img {
                    width: 20px !important;
                    opacity: 0 !important;
                }
                span {
                    display: block;
                    text-transform: uppercase;
                    color: #000;
                    opacity: 0;
                    font-family: 'Montserrat';
                }
                h3 {
                    width: 70%;
                    @media only screen and (max-width: 767px) {
                        width: 100% !important;
                    }
                    margin: 0 auto;
                    opacity: 0;
                    font-size: 20px;
                    color: #000;
                    font-weight: 400;
                    padding: 40px 0 15px 0;
                }
                p {
                    opacity: 0;
                }
                ._btn_view_product {
                    opacity: 0;
                }
            }
            .center {
                .details {
                    margin-top: 100px;
                    color: #000;
                    span {
                        opacity: 0.2;
                    }
                    h3 {
                        width: 70%;
                        margin: 0 auto;
                        opacity: 0;
                        @media only screen and (max-width: 767px) {
                            font-size: 26px !important;
                        }
                    }
                    p {
                        opacity: 1;
                        width: 70%;
                        @media only screen and (max-width: 767px) {
                            width: 100%;
                        }
                        margin: 40px auto;
                        opacity: 1;
                    }
                }
                ._btn_view_product {
                    opacity: 1;
                }
            }
            .owl-item.active.center {
                position: relative;
                img {
                    width: 20px !important;
                    opacity: 1 !important;
                }
                .cover {
                    height: 500px;
                    background-size: cover;
                    position: absolute;
                    right: 0;
                    top: 0;
                    margin-left: 5%;
                    @media only screen and (max-width: 1300px) {
                        margin-left: 0 !important;
                    }
                    width: 30%;
                    @media only screen and (max-width: 1199px) {
                        position: static !important;
                        width: 100%;
                    }
                }
                h3 {
                    opacity: 1 !important;
                }
                .details {
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 65%;
                    @media (min-width: 1200px) and (max-width: 1399px) {
                        width: 70% !important;
                    }
                    @media only screen and (max-width: 1199px) {
                        position: static !important;
                        width: 100%;
                    }
                }
            }
        }
        .facilities-slide {
            .owl-nav {
                width: 100%;
                display: block !important;
                position: absolute;
                top: 35%;
                @media only screen and (max-width: 991px) {
                    top: 20% !important;
                }
                z-index: 6;
                button.owl-prev {
                    &:focus {
                        outline: 0 !important;
                    }
                    span {
                        font-size: 40px;
                        position: relative;
                        top: -8px;
                    }
                    opacity: 0.5;
                    @include transition();
                    &:hover {
                        @include transition();
                        opacity: 1;
                    }
                    position: absolute;
                    background: #fff;
                    width: 50px;
                    height: 50px;
                    left: 0;
                }
                button.owl-next {
                    &:focus {
                        outline: 0;
                    }
                    span {
                        font-size: 40px;
                        position: relative;
                        top: -8px;
                    }
                    @include transition();
                    opacity: 0.5;
                    &:hover {
                        @include transition();
                        opacity: 1;
                    }
                    position: absolute;
                    right: 0;
                    background: #fff;
                    width: 50px;
                    height: 50px;
                }
            }
            .owl-item > div:after {
                font-family: sans-serif;
                font-size: 24px;
                font-weight: bold;
            }
        }
        #products-item {
            img {
                max-height: 350px;
                width: auto;
                margin: 0 auto;
            }
        }
    }
}
section#maps {
    @media screen and (max-width: 991px) {
        div#wpgmza_map {
            margin-bottom: 60px;
        }
    }
    position: relative;
    .col-md-8 {
        position: relative;
        .overlay-maps {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            background: 0;
            z-index: 999;
        }
    }
    //    @media only screen and (min-width: 1200px) {
    //        top: -100px;
    //    }
    h3 {
        @media only screen and (max-width: 991px) {
            margin-left: 50px;
        }
        font-weight: 400;
        margin-bottom: 20px;
    }
    .image-maps {
        &.hide-icon {
            &::after {
                opacity: 0;
            }
            &::before {
                opacity: 0;
            }
        }
        &::after {
            @media screen and (max-width: 991px) {
                display: none;
            }
            content: '';
            background-image: url(../images/marker.png);
            width: 50px;
            height: 60px;
            position: absolute;
            top: 27%;
            z-index: 999;
            background-size: cover;
            background-position: center;
            left: 42%;
        }
        &::before {
            @media only screen and (max-width: 991px) {
                display: none;
            }
            content: '';
            border-bottom: 2px solid #000;
            width: 420px;
            height: 60px;
            position: absolute;
            top: 20%;
            z-index: 999;
            background-size: cover;
            background-position: center;
            left: 50%;
        }
    }
    .img-icon {
        right: 0;
        position: absolute;
        top: 7px;
    }
    .address {
        margin-top: 100px;
        @media only screen and (min-width: 992px) {
            margin-left: 60px;
        }
    }
}
section#news-events {
    .item {
        .cover-size {
            height: 250px;
            overflow: hidden;
        }
        .cover {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            @include transition(0.5);
            width: 100%;
            height: 250px;
            position: relative;
            &::after {
                @include transition(0.5);
                content: '';
                left: 0;
                outline: #a9976d solid 1px;
                outline-offset: -30px;
                right: 0;
                width: 100%;
                display: block;
                top: 0;
                bottom: 0;
                position: absolute;
                opacity: 0;
            }
        }
        &:hover {
            cursor: pointer;
            .cover {
                @include transition(0.5);
                transform: scale(1.1);
                &::after {
                    @include transition(0.5);
                    opacity: 1;
                }
            }
        }
    }
    padding: 50px 0;
    a {
        color: #000;
    }
    &.sg-news {
        h2 {
            font-size: 28px;
            font-weight: 400;
        }
        .time {
            padding-bottom: 20px;
            img {
                margin: 10px 0;
            }
        }
        p {
            text-align: left;
        }
    }
    .footer-section-news {
        margin-bottom: 25px;
    }
    .lSSlideOuter .lSPager.lSpg > li a {
        height: 30px !important;
        width: 30px !important;
        text-indent: inherit!important;
        background: none !important;
        text-align: center;
    }
    li.active a {
        color: #000 !important;
    }
    ul.lSPager.lSpg li {
        a {
            color: #bfbfbf;
        }
    }
    ul.lSPager.lSpg li.active {
        &::after {
            content: '';
            border-bottom: 1px solid #000;
            position: relative;
            width: 80px;
            display: block;
            top: -25px;
            right: 0;
            margin: 0 0 0 30px;
        }
        a {
            position: relative;
        }
    }
    ul.lSPager.lSpg {
        padding: 20px 0;
        float: right;
        text-align: left;
    }
    .title {
        h2 {
            padding: 20px;
            font-size: 22px;
            font-weight: 400;
            color: $secondColor;
        }
    }
    .time {
        p {
            font-size: 14px !important;
            color: $mainColor;
        }
        img {
            margin-left: 20px;
            margin-right: 5px;
            width: 16px;
            display: inline;
        }
    }
    .details {
        p {
            padding: 20px 20px 50px;
        }
    }
}
section#meeting-banquet {
    .floor-plan {
        margin-top: 50px;
    }
    .meeting_table {
        p {
            padding: 5px 10px;
        }
        .meeting-title {
            color: $secondColor;
            text-align: left !important;
        }
        padding: 50px 0;
        tbody {
            background: #edeae7;
        }
        thead {
            color: #fff;
            background: $secondColor;
        }
        .table-striped > tbody > tr:nth-of-type(odd) {
            background-color: #f2f1ed;
        }
    }
    .title {
        h2 {
            font-size: 24px;
            font-weight: 400;
            text-transform: uppercase;
            @media screen and (min-width: 1200px) {
                //                margin: 0 0 40px 80px;
            }
            @media screen and (max-width: 1199px) {
                margin: -20px 0;
            }
        }
    }
    padding: 50px 0;
    .items {
        margin: 30px 0;
        @media screen and (min-width: 1200px) {
            position: relative;
            height: 320px;
            margin: 40px 20px 20px 20px;
        }
    }
    .cover-size {
        @media screen and (max-width: 767px) {
            height: 250px;
            overflow: hidden;
        }
        height: 450px;
        overflow: hidden;
        @media screen and (min-width: 1200px) {
            width: 300px;
            overflow: hidden;
            height: 300px;
        }
    }
    .cover {
        @include transition(0.5);
        &:hover {
            @include transition(0.5);
            transform: scale(1.1);
        }
        cursor: pointer;
        left: 0;
        @media screen and (max-width: 767px) {
            height: 250px;
        }
        height: 450px;
        @media screen and (min-width: 1200px) {
            width: 300px;
            height: 300px;
        }
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    .details {
        h2 {
            text-transform: uppercase;
            padding: 20px 15px 10px 15px;
            font-size: 24px;
            font-weight: 400;
            color: $secondColor;
        }
        background: #fff;
        @media screen and (min-width: 1200px) {
            position: absolute;
            right: 0;
            top: 40px;
            width: 350px;
        }
        padding-bottom: 20px;
        p {
            margin-right: 20px;
            padding: 20px 20px 0;
            @media screen and (min-width: 1200px) {
                height: 140px;
                overflow-y: hidden;
                &:hover {
                    @include transition();
                    overflow-y: auto;
                }
            }
        }
        p::-webkit-scrollbar {
            background-color: #d2a97a;
            border-radius: 4px;
            height: 8px;
            width: 2px
        }
        p::-webkit-scrollbar-thumb {
            background-color: $secondColor;
            border-radius: 4px
        }
    }
    .meeting-type {
        @media screen and (max-width: 1199px) {
            position: relative;
            left: 20px;
        }
        @media screen and (min-width: 1200px) {
            position: absolute;
            right: 100px;
            bottom: 20px;
        }
        img {
            display: inline-block;
            margin-top: -15px;
        }
        p {
            line-height: 16px;
            color: $secondColor;
            text-transform: uppercase;
            font-size: 14px;
            display: inline-block;
        }
        span {
            line-height: 16px;
            display: block;
        }
    }
}
//*
.custom-pagination {
    text-align: center;
    padding: 0 !important;
}
@media screen and (max-width: 991px) {
    .custom-pagination {
        text-align: left;
    }
    a.page-numbers {
        background: #231F20;
        padding: 5px 10px !important;
        color: #fff;
        transition: all .2s ease-in-out;
        display: inline-block;
        margin: 3px 0px;
    }
    .span.page-numbers.current {
        padding: 10px !important;
    }
    a.score-all {
        padding: 5px 10px !important;
        display: inline-block;
    }
}
.news_events_page {
    span.page-numbers.current {
        background: #012b5d;
        padding: 10px 20px;
        color: #fff;
    }
    a.page-numbers {
        background: #ccc;
        padding: 10px 20px;
    }
}
span.page-numbers.current {
    background: #012b5d;
    padding: 15px 20px;
    color: #fff;
    @media screen and (min-width: 992px) and (max-width: 1199px) {
        padding: 10px 15px !important;
    }
    @media screen and (max-width: 991px) {
        display: inline-block;
        padding: 5px 10px !important;
    }
}
a.page-numbers._gold {
    background: $secondColor;
    padding: 10px 20px;
    color: #fff;
    @media screen and (min-width: 992px) and (max-width: 1199px) {
        padding: 10px 15px !important;
    }
}
a.score-all {
    margin: 0 5px;
    background: $mainColor;
    padding: 15px 32px;
    color: #fff;
    &:hover {
        background: $secondColor;
    }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
        padding: 10px 34px !important;
    }
}
a.page-numbers {
    background: #231F20;
    padding: 15px 20px;
    color: #fff;
    @media screen and (min-width: 992px) and (max-width: 1199px) {
        padding: 10px 15px !important;
    }
    @include transition();
    &:hover {
        @include transition();
        background: $secondColor !important;
        color: #fff;
    }
}
.related_post {
    margin-bottom: 30px;
    .container {
        border-top: 1px solid rgba(108, 71, 29, .21);
    }
    h3 {
        margin: 50px 0 30px 0;
        @media screen and (max-width: 991px) {
            margin: 50px 0;
        }
        text-transform: uppercase;
        font-size: 26px;
        font-weight: 400 !important;
    }
    .time {
        img {
            margin: 0 5px 0 20px !important;
        }
    }
}
ul#relate_slide {
    min-height: 360px;
}
.bremadcrumb > li + li:before {
    display: none !important;
}
#golf-course {
    margin-top: 50px;
    overflow: hidden;
    i.fa.fa-search-plus {
        position: relative;
        top: 0;
        left: 130px;
        font-size: 24px;
    }
    .title-hole {
        position: absolute;
        transform: rotate(90deg);
        top: 50%;
        left: -120px;
        text-transform: uppercase;
        p {
            display: inline;
        }
    }
    ._border {
        border-bottom: 1px solid #000;
        position: relative;
        top: -5px;
        width: 100px;
        height: 1px;
        display: inline-block;
        left: 10px;
        margin-right: 20px;
    }
    .par-number {
        position: absolute;
        top: 65px;
        right: 50px;
        margin-bottom: 20px;
        p {
            text-transform: uppercase;
            color: #000;
        }
        span {
            font-size: 50px;
            color: #000;
            padding-left: 10px;
        }
    }
    .maps-box {
        height: 197px;
        @media screen and (max-width: 1199px) {}
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        .btn-zoom {
            position: absolute;
            right: 0;
            z-index: 99;
            background: $secondColor;
            width: 30px;
            height: 30px;
            color: #fff;
            text-align: center;
            font-size: 20px;
            @media screen and (max-width: 1199px) {
                right: 15px;
            }
        }
    }
    .bg-details {
        padding: 20px;
        background: $mainColor;
        text-align: center;
        color: #fff;
        .num {
            font-size: 60px;
            margin-top: 25px;
            margin-bottom: 0;
            @media screen and (min-width: 992px) {
                border-right: 1px solid #fff;
            }
            line-height: 80px;
        }
    }
    .custom-navi {
        position: relative;
        top: -70px;
        a.prev.page-numbers {
            display: inline-block !important;
            position: absolute;
            left: 0;
            top: 0;
            background: #fff;
            border: 1px solid #ccc;
            color: #000;
            &:hover {
                color: #fff;
            }
        }
        a.next.page-numbers {
            display: inline-block !important;
            position: absolute;
            right: 0;
            color: #000;
            background: #fff;
            border: 1px solid #ccc;
            top: 0;
            &:hover {
                color: #fff;
            }
        }
        a.page-numbers {
            display: none;
        }
        span.page-numbers.current {
            display: none;
        }
    }
    .hole-list {
        position: absolute;
        top: 240px;
        @media screen and (min-width: 992px) and (max-width: 1199px) {
            top: 450px !important;
        }
        @media screen and (max-width: 991px) {
            top: 750px;
        }
    }
    .all-details {
        padding: 50px 30px;
        @media screen and (max-width: 991px) {
            padding: 20px 0;
        }
        h2 {
            @media screen and (max-width: 991px) {
                font-size: 24px !important;
            }
            padding: 20px 0;
            font-weight: 400;
            text-transform: uppercase;
        }
        h3 {
            @media screen and (max-width: 991px) {
                font-size: 22px !important;
            }
            padding: 60px 0 30px;
            font-weight: 400;
            text-transform: uppercase;
        }
        .hole-number {
            padding: 15px 0;
            p {
                @media screen and (max-width: 991px) {
                    text-align: right;
                }
                text-transform: uppercase;
                color: #000;
                span {
                    font-size: 50px;
                    color: #000;
                }
            }
        }
        .details {
            padding: 30px 0 60px;
        }
        .title {
            @media screen and (max-width: 991px) {
                text-align: left !important;
            }
        }
    }
    .yards-list {
        opacity: 1 !important;
        position: relative;
        p {
            text-transform: uppercase;
            color: #888 !important;
        }
        background: #fff;
        color: #000;
        width: 100% !important;
        margin: 0;
        text-align: center;
        padding: 10px;
        &:hover {
            cursor: pointer;
            outline: none;
        }
        &:focus {
            outline: none;
        }
        &:focus-within {
            outline: none;
        }
        &.slick-active {
            background: #fff;
            outline: none;
        }
        &.slick-slide:nth-of-type(1) {
            &.slick-current {
                &::after {
                    content: '';
                    background-image: url(../images/black.png);
                    position: absolute;
                    z-index: 99;
                    left: 35%;
                    width: 20px;
                    height: 25px;
                    background-repeat: no-repeat;
                    top: 8px;
                    @media screen and (max-width: 767px) {
                        left: 25%;
                    }
                }
            }
            &:hover {
                &::after {
                    content: '';
                    background-image: url(../images/black.png);
                    position: absolute;
                    z-index: 99;
                    left: 35%;
                    width: 20px;
                    height: 25px;
                    background-repeat: no-repeat;
                    top: 8px;
                    @media screen and (max-width: 767px) {
                        left: 25%;
                    }
                }
            }
            background-color: #000000;
            outline: none;
            p {
                color: #fff !important;
            }
        }
        &.slick-slide:nth-of-type(2) {
            &.slick-current {
                &::after {
                    content: '';
                    background-image: url(../images/blue.png);
                    position: absolute;
                    z-index: 99;
                    left: 35%;
                    width: 20px;
                    height: 25px;
                    background-repeat: no-repeat;
                    top: 8px;
                    @media screen and (max-width: 767px) {
                        left: 25%;
                    }
                }
            }
            &:hover {
                &::after {
                    content: '';
                    background-image: url(../images/blue.png);
                    position: absolute;
                    z-index: 99;
                    left: 35%;
                    width: 20px;
                    height: 25px;
                    background-repeat: no-repeat;
                    top: 8px;
                    @media screen and (max-width: 767px) {
                        left: 25%;
                    }
                }
            }
            background-color: #002b5c;
            outline: none;
            p {
                color: #fff !important;
            }
        }
        &.slick-slide:nth-of-type(3) {
            &.slick-current {
                &::after {
                    content: '';
                    background-image: url(../images/white.png);
                    position: absolute;
                    z-index: 99;
                    left: 35%;
                    width: 20px;
                    height: 25px;
                    background-repeat: no-repeat;
                    top: 8px;
                    @media screen and (max-width: 767px) {
                        left: 25%;
                    }
                }
            }
            &:hover {
                &::after {
                    content: '';
                    background-image: url(../images/white.png);
                    position: absolute;
                    z-index: 99;
                    left: 35%;
                    width: 20px;
                    height: 25px;
                    background-repeat: no-repeat;
                    top: 8px;
                    @media screen and (max-width: 767px) {
                        left: 25%;
                    }
                }
            }
            background-color: #fff;
            outline: none;
            p {
                color: #000 !important;
            }
        }
        &.slick-slide:nth-of-type(4) {
            &.slick-current {
                &::after {
                    content: '';
                    background-image: url(../images/red.png);
                    position: absolute;
                    z-index: 99;
                    left: 35%;
                    width: 20px;
                    height: 25px;
                    background-repeat: no-repeat;
                    top: 8px;
                    @media screen and (max-width: 767px) {
                        left: 25%;
                    }
                }
            }
            &:hover {
                &::after {
                    content: '';
                    background-image: url(../images/red.png);
                    position: absolute;
                    z-index: 99;
                    left: 35%;
                    width: 20px;
                    height: 25px;
                    background-repeat: no-repeat;
                    top: 8px;
                    @media screen and (max-width: 767px) {
                        left: 25% !important;
                    }
                }
            }
            background-color: #820c18;
            outline: none;
            p {
                color: #fff !important;
            }
        }
    }
    #holes-gallery {
        .thumb-cover {
            display: inline-block;
            height: 100px !important;
            width: 100px !important;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
    .slider {
        .slick-list {
            margin-top: 30px;
        }
        padding: 100px 0;
        @media screen and (max-width: 991px) {
            padding: 100px 0 50px 0;
        }
        .cover-gallery {
            cursor: pointer;
            margin: 0px 20px 0 80px;
            height: 650px;
            background-size: contain;
            background-position: top;
            background-repeat: no-repeat;
        }
    }
    .thumb-cover.slick-slide {
        filter: grayscale(100);
        margin: 1px;
        cursor: pointer;
    }
    .thumb-cover.slick-current {
        filter: grayscale(0) !important;
        outline: 1px solid #ccc;
        outline-offset: -5px;
    }
}
.slider-nav-thumbnails .slick-slide {
    opacity: 0.5;
}
.slider-nav-thumbnails .slick-slide.slick-active {
    opacity: 1;
    background: green;
}
.lg-img-wrap img {
    max-height: 70vh !important;
}
#restaurant {
    margin-bottom: 70px;
    .title {
        margin: 50px 0;
    }
    .prev {
        @media screen and (max-width: 1050px) {
            display: none;
        }
        &:hover {
            cursor: pointer;
        }
        position: absolute;
        top: 45%;
        line-height: 0px;
        color: $secondColor;
        left: -25px;
        z-index: 999;
        img {
            width: 50px;
        }
    }
    .next {
        &:hover {
            cursor: pointer;
        }
        img {
            width: 50px;
        }
        @media screen and (max-width: 1050px) {
            display: none;
        }
        z-index: 999;
        position: absolute;
        top: 45%;
        line-height: 0px;
        color: $secondColor;
        right: -25px;
        a.slideNext {}
    }
    .content-box {
        padding: 40px;
        background: #f7f7f7;
        h3 {
            color: $secondColor;
            margin: 20px 0;
        }
        ul {
            margin: 20px 0;
            li {
                margin: 10px 0;
                p {
                    text-transform: uppercase;
                }
                img {
                    display: inline-block;
                }
            }
        }
    }
    .cover-box {
        overflow: hidden;
        .cover {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            height: 450px;
            @include transition(0.5);
            &:hover {
                cursor: pointer;
                transform: scale(1.1);
                @include transition(0.5);
            }
        }
    }
}
#membership {
    tr.capitalize {
        &:hover {
            background: $secondColor;
            cursor: pointer;
            color: #fff;
            p {
                color: #fff !important;
            }
        }
    }
    tr {
        &:hover {
            background: $mainColor;
            cursor: pointer;
            color: #fff;
            p {
                color: #fff !important;
            }
        }
    }
    .main-details {
        margin-top: 40px;
    }
    .member-type {
        .img-icon {
            margin: 0 auto;
            position: absolute;
        }
        .icon-center {
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            text-align: center;
            z-index: 9;
            max-width: 90px;
            top: 40%;
            @media screen and (max-width: 991px) {
                top: 45% !important;
            }
        }
        position: relative;
        margin: 40px 0;
        .row {
            overflow: hidden;
        }
        .corporate {
            @include transition(0.5);
            &:hover {
                cursor: pointer;
                @include transition(0.5);
                transform: scale(1.1);
            }
            min-height: 235px;
            @media screen and (min-width: 992px) and (max-width: 1199px) {
                min-height: 266px;
            }
            h2 {
                font-size: 20px !important;
            }
            p {
                margin-top: 10px;
                font-size: 13px;
            }
            color: #fff;
            padding: 50px 80px;
            @media screen and (max-width: 991px) {
                padding: 50px 0;
            }
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
    .membership-fee {
        h2 {
            margin: 20px 0;
        }
        h3 {
            margin: 20px 0;
        }
    }
    .extra-conditions {
        margin: 60px 0 100px 0;
    }
}
#login-page {
    padding: 100px 0px 130px;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    background-image: url(../images/login-bg.jpg);
    min-height: 400px;
    .register-form {
        padding: 50px;
        @media screen and (max-width: 991px) {
            padding: 50px 50px 80px;
        }
        margin-top: 30px;
        background: #fff;
        border: 1px solid rgba(150, 110, 65, 0.34);
        .form-control {
            border: none;
            box-shadow: none;
        }
    }
}
#member-privileges {
    margin: 50px 0;
    h1 {
        color: $secondColor;
        letter-spacing: 4px;
        text-transform: uppercase;
    }
    h2 {
        font-size: 24px;
        color: $secondColor;
        text-transform: uppercase;
    }
    .main-content {
        margin: 50px 0;
        padding: 50px;
        min-height: 400px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(../images/bg-card.png);
        h2 {
            letter-spacing: 2px;
        }
        h3 {
            margin: 10px 0;
            text-transform: uppercase;
            font-size: 20px;
        }
        p {
            margin-top: 30px;
        }
        .details {
            margin-top: 90px;
        }
    }
    .exclusive-offer {
        padding: 20px 100px;
        border: 2px solid $secondColor;
        display: inline-block;
    }
    .promotions {
        margin: 50px 0;
        .item {
            margin: 20px 0;
            @include transition();
            &:hover {
                cursor: pointer;
                @include transition();
                .cover {
                    .circle-bg {
                        @include transition();
                        background: $mainColor;
                        p {
                            @include transition();
                            color: $secondColor;
                        }
                        span {
                            @include transition();
                            color: $secondColor;
                        }
                    }
                }
            }
            .cover {
                padding: 20px;
                /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e7e7e7+0,ffffff+100 */
                background: rgb(231, 231, 231);
                /* Old browsers */
                background: -moz-linear-gradient(top, rgba(231, 231, 231, 1) 0%, rgba(255, 255, 255, 1) 100%);
                /* FF3.6-15 */
                background: -webkit-linear-gradient(top, rgba(231, 231, 231, 1) 0%, rgba(255, 255, 255, 1) 100%);
                /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom, rgba(231, 231, 231, 1) 0%, rgba(255, 255, 255, 1) 100%);
                /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                /* IE6-9 */
                .circle-bg {
                    background: $secondColor;
                    width: 200px;
                    height: 200px;
                    border-radius: 50%;
                    margin: 0 auto;
                    p {
                        font-size: 70px;
                        font-family: 'Snell Roundhand';
                        color: #c9c0af;
                        text-align: center;
                        position: absolute;
                        top: 30%;
                        left: 50%;
                        transform: translateX(-50%) translateY(-50%);
                        text-transform: uppercase;
                    }
                    span {
                        font-size: 18px;
                        color: #c9c0af;
                        text-align: center;
                        text-transform: uppercase;
                        position: absolute;
                        top: 45%;
                        left: 50%;
                        transform: translateX(-50%) translateY(-50%);
                    }
                }
            }
            .content {
                p {
                    font-family: 'Gotham';
                }
                padding: 20px;
                margin-top: 20px;
                border-top: 2px solid #000;
            }
        }
    }
    .conditions {
        margin: 80px 0 50px 0;
    }
}
#food-beverage {
    .flexslider {
        background: none;
    }
    hr {
        margin-bottom: 0 !important;
    }
    .item {
        background: #fff;
        @include transition();
        &:hover {
            cursor: pointer;
            @include transition();
            background: #f5f5f5;
        }
        padding: 40px 15px;
        &.promotions {
            background: #f5f5f5;
            @include transition();
            &:hover {
                cursor: pointer;
                @include transition();
                background: #f5f5f5;
            }
            .title-section {
                .details-section {
                    @media screen and (max-width: 991px) {
                        position: static;
                        margin-bottom: 40px;
                    }
                    bottom: 30%;
                    h2 {
                        font-size: 20px;
                        color: #000;
                        margin-top: 5px;
                        font-family: 'Gotham';
                        text-transform: uppercase;
                        font-weight: 400; //                        margin-bottom: 10px;
                    }
                    img {
                        padding-right: 100px;
                        margin: 20px 10px;
                    }
                }
            }
        }
        margin: 0 0 20px 0;
        .cover {
            position: relative;
            height: 400px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            @include transition(0.5);
            &:hover {
                @include transition(0.5);
                &::after {
                    @include transition(0.5);
                    content: '';
                    background: rgba($mainColor, 0.8);
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                }
                .promotion-title {
                    opacity: 1;
                }
            }
            .promotion-title {
                color: #fff;
                top: 50%;
                bottom: 0;
                right: 0;
                left: 0;
                text-align: center;
                margin: 0 auto;
                position: absolute;
                font-size: 20px;
                z-index: 9;
                opacity: 0;
            }
        }
        .title-section {
            @media screen and (min-width: 992px) {
                height: 400px;
            }
            position: relative;
            .details-section {
                position: absolute;
                bottom: 0; //                border-bottom: 1px solid #ccc;
                h2 {
                    font-size: 14px;
                    text-transform: uppercase;
                    color: $secondColor;
                }
                p {
                    margin-top: 5px;
                    font-family: 'Gotham';
                    text-transform: uppercase;
                    font-weight: 400; //                        margin-bottom: 10px;
                }
            }
        }
        .title {
            p {
                margin: 15px 0;
                @media screen and (max-width: 991px) {
                    margin: 15px 0 40px;
                }
                color: $secondColor;
            }
        }
    }
}
div#lightbox {
    top: 30vh !important;
}
#restaurant .flexslider {
    height: inherit;
}
span.lg-close.lg-icon {
    color: $secondColor;
}
.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
    border-color: $secondColor;
}
span#lg-share,
span#lg-actual-size,
span#lg-zoom-in,
span#lg-zoom-out,
span.lg-fullscreen.lg-icon,
span.lg-autoplay-button.lg-icon,
a#lg-download,
span.lg-toogle-thumb.lg-icon {
    display: none;
}
.lg-thumb.lg-group {
    margin: 0 auto;
}
ul.food-beverage-slide.lightSlider {
    min-height: 550px;
}
@media screen and (max-width: 991px) {
    .dpsp-column-3.dpsp-has-spacing .dpsp-networks-btns-wrapper li {
        width: 25% !important;
    }
    div#dpsp-content-bottom {
        width: 20% !important;
    }
}
@media screen and (max-width: 560px) {
    div#dpsp-content-bottom {
        width: 30% !important;
    }
}
.lb-dataContainer {
    position: relative;
    top: -75vh;
    /* right: 270px; */
}
