.wiForecasts {
    display: none;
}

.flatWeatherPlugin {

    h2 {
        display: none !important;

    }

    sup {

        font-size: 16px !important;
        top: 0 !important;
        opacity: 1 !important;

    }
}

.flatWeatherPlugin div.wiToday > p.wiTemperature {
    top: 83px;
    position: relative;
    font-size: 16px!important;
    left: -32px;
    @media screen and (max-width: 991px) {
        top: -20px;
        position: relative;
        font-size: 16px!important;
        left: -100px;
        width: 100%;
    }
}

.wiIconGroup {
    position: relative;
    top: 0;
    left: -70px;
    width: 100%;
    @media screen and (max-width: 991px) {
        left: 0 !important;
        float: none !important;
        width: 100% !important;
    }

}

p.wiDay {
    position: relative;
    top: -20px;
    left: -15px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 400!important;
    @media screen and (max-width: 991px) {
        top: -44px;
        left: -25px;
    }
}

p.wiText {
    position: relative;
    top: 25px;
    text-align: left;
    left: -5px;
    @media screen and (max-width: 991px) {
        left: 0 !important;
        text-align: center !important;
    }
}

#localTime {
    position: relative;
    top: 105px;
    left: 70px;
    @media screen and (max-width: 991px) {
        top: 104px;
        left: 60px;
    }
}

.wi.wi802 {
    position: relative;
    left: 25px;
    @media screen and (max-width: 991px) {
        left: 0;
    }
}

@media screen and (min-width: 992px) {
    .flatWeatherPlugin .wiToday > .wiIconGroup p {
        width: 200% !important;
    }

}
