.page-cover {

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 550px;
    @media screen and (max-width: 480px) {
        height: 300px;
    }
}

.content-about-page {
    background-image: url(../images/bg-page-about.png);
    min-height: 500px;
    background-position: top;
    background-repeat: no-repeat;
    padding: 50px 0 0 0;
    .title {
        padding: 20px 0;
        h1 {
            text-transform: uppercase;
            font-weight: 400;
            margin: 20px 0;
        }
        h2 {
            color: $secondColor;
            font-size: 18px;
            font-weight: 400;
        }

    }
    .main-content {

        @media only screen and (min-width: 1200px) {
            padding-right: 50px;
        }

    }
    .second-content {
        padding-bottom: 40px;
        @media only screen and (min-width: 1200px) {
            padding-left: 50px;
        }

    }
    .highlight {
        margin: 50px 0;
        text-align: center;
        .main-details {
            p {
                font-size: 68px;
                font-style: italic;
                color: $secondColor;
                margin: 30px 0 20px 0;
            }
        }
        .sub-details {
            p {
                font-size: 40px;

                color: $secondColor;
                padding: 20px 0;
            }
        }
        .description {
            margin-top: 30px;
            padding-bottom: 30px;

        }
    }
    .news-and-events {
        background-image: url(../images/bg-footer-about-page.png);
        min-height: 700px;
        background-size: cover;
        background-position: top;
        background-attachment: fixed;
        background-repeat: no-repeat;
        padding: 50px 0;
        .highlight-news {
            img {

                max-width: 200px;
            }
            .main-content {
                width: 90%;
                text-align: center;
                margin: 20px auto 0;
                p {
                    font-size: 21px;
                    line-height: 30px;
                    font-weight: 600;
                    margin-bottom: 10px;
                }

            }
            .sub-content {
                width: 90%;
                text-align: center;
                margin: 0 auto;


            }

        }
        ._btn_download {
            width: 30% !important;
            margin: 20px auto;
            @media only screen and (max-width: 767px) {
                width: 80% !important;
            }

        }

        .news-list {
            padding: 40px 0 20px;
            .item {
                .cover-size {
                    height: 250px;
                    overflow: hidden;
                }
                .cover {
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;

                    @include transition(0.5);
                    width: 100%;
                    height: 255px;
                    position: relative;
                    &::after {
                        @include transition(0.5);
                        content: '';
                        left: 0;
                        outline: #a9976d solid 1px;
                        outline-offset: -30px;
                        right: 0;
                        width: 100%;
                        display: block;
                        top: 0;
                        bottom: 0;
                        position: absolute;
                        opacity: 0;
                    }
                }

                &:hover {

                    cursor: pointer;
                    .cover {

                        @include transition(0.5);
                        transform: scale(1.1);
                        &::after {
                            @include transition(0.5);
                            opacity: 1;
                        }

                    }
                }

            }
            .title {
                color: #fff;
                h3 {
                    padding: 0 20px;
                    font-weight: 400;
                    font-size: 22px;
                }
            }
            .content {
                margin-bottom: 30px;
                p {
                    padding: 0 20px;
                    color: #fff;
                }
            }
        }
    }
    .last-section {
        background-image: url(../images/last-section-about.png);
        min-height: 700px;
        background-size: cover;
        background-position: top;
        background-repeat: no-repeat;
        padding: 50px 0;
        h2 {
            padding: 40px 0;
            text-align: center;
            font-weight: 400;
            color: $secondColor;
        }
        p {
            @media screen and (min-width: 992px) {
                width: 80%;
            }
            margin: 10px auto;

        }


    }
}

.custom-pagination {
    padding: 20px;
}
