@media screen and (min-width: 1200px) {
    li.current.show img {
        max-height: 60vh;
        width: auto;
        margin: 0 auto;
    }
    .slideshow figure {
        border: 0 !important;
        overflow: inherit !important;
        background: 0 !important;
    }
}

@media screen and (max-width: 1199px) {
    li.current.show img {
        max-height: 45vh;
        position: absolute;

        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: auto;
        margin: 0 auto;
        z-index: 999;

    }

}

.slideshow figure {
    background: #fff !important;
    border: 0px solid rgba(#002b5c, 0.7) !important;

}


.slideshow {
    z-index: 2000;
    background: rgba(#002b5c, 0.7) !important;
}

.info-keys.icon {
    display: none !important;
}

.slideshow li:after {
    background: 0 !important;
}

@media screen and (min-width: 961px) {
    .slideshow nav span {

        color: #fff;

    }
    .slideshow nav span.nav-close {
        color: #fff;
    }
    .slideshow figure {
        background: 0 !important;
        border: 0px solid rgba(#002b5c, 0.7) !important;

    }
}

.slideshow nav span {

    font-size: 1.5em;
}

.gallery_btn.filters-button-group li {
    display: inline;
}

.gallery_btn.filters-button-group {
    @media screen and (min-width: 768px) {
        margin-top: -42px;
    }
    text-align: center;
}


li.img-gallery {
    overflow: hidden;
    position: relative;
    &:hover {
        @include transition(0.5);
        figure {
            overflow: hidden;
            padding: 5px;
            opacity: 0.5;

        }
        &::after {
            @include transition(0.5);
            content: "\e881";
            text-align: center;
            display: table-cell;
            vertical-align: middle;
            font-family: Linearicons-Free;
            position: absolute;
            left: 0;
            right: 0;
            top: 35%;
            bottom: 0;
            font-size: 30px;
            color: #fff;

        }
        &::before {
            @include transition(0.5);
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: 5px;
            background: rgba(black, 1);

        }
    }
}

//.grid-gallery {
//    li {
//        overflow: hidden;
//        position: relative;
//        &:hover {
//            @include transition(0.5);
//            figure {
//                overflow: hidden;
//                padding: 5px;
//                opacity: 0.5;
//                
//            }
//            &::after {
//                @include transition(0.5);
//                content: "\e881";
//                text-align: center;
//                display: table-cell;
//                vertical-align: middle;
//                font-family: Linearicons-Free;
//                position: absolute;
//                left: 0;
//                right: 0;
//                top: 35%;
//                bottom: 0;
//                font-size: 30px;
//                color: #fff;
//
//            }
//            &::before {
//                @include transition(0.5);
//                content: '';
//                position: absolute;
//                left: 0;
//                right: 0;
//                top: 0;
//                bottom: 0;
//                margin: 5px;
//                background: rgba(black, 1);
//
//            }
//        }
//    }
//}
ul.grid {
    margin-top: 30px;
}

.title_gall {
    position: relative;
    z-index: 998;
    background: #fff;
}

.bg-remove {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: 0;
    z-index: 2;
}

.grid-wrap {
    max-width: inherit !important;
}

.btn_vdo_clip {
    position: absolute;
    background: #fff;
    top: 30px;
    z-index: 999;
    display: block;
    width: 100%;
    display: none;
    &.show {
        display: block;
    }
}

.btn_360 {
    position: absolute;
    background: #fff;
    top: 30px;
    z-index: 999;
    display: block;
    width: 100%;
    display: none;
    &.show {
        display: block;
    }
}

#grid-gallery {
    .container {
        position: relative;
    }
}

.title_gallery {
    overflow: hidden;
    height: 30px !important;
    li.select-item.all-gallery {
        width: 100%;
        background: #fff !important;
        z-index: 99;
    }
    li {
        position: absolute;
        top: 0 !important;
    }
}

div#grid-gallery {
    margin-bottom: 30px;
}

li.select-item.video {
    padding: 5px;
    position: relative;

    img.btn-play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: .3;
        width: 50px;
    }
}

li.select-item.virtual {
    padding: 5px;
    position: relative;
}

@media screen and (max-width: 767px) {
    .btn_style_3 {
        background: #966e41;
        border: 1px solid #966e41;
        display: block;
        width: 100% !important;
        padding: 10px 15px;
        margin: 5px 0!important;
    }
    .slideshow figure {
        z-index: 2000;
        background: rgba(0, 43, 92, .7)!important;
    }
    .slideshow span {
        color: #fff !important;
    }
}


img.btn-play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: .3;
    width: 50px;
}

.thumb-cover {
    position: relative;
}

//@media screen and (min-width: 992px)
//{
//    .slideshow li:last-child {
//    width: 1170px;
//    height: 700px;
//    position: absolute;
//    top: 10%;
//    left: 0;
//    margin: 0 auto;
//    visibility: inherit!important;
//    right: 0;
//    iframe {
//        z-index: 99999;
//        width: 1170px;
//        height: 700px !important;
//        top: 0;
//        position: absolute;
//        max-width: 100%;
//    }
//}
//
//}
body.overflow_hidden {
    overflow: hidden;
}
.slideshow nav span {
    z-index: 2500 ;
}

span.removebg {
    width: 100%;
    height: 100%;
    display: block;
    z-index: 99;
    background: #fff0;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 99999;
    top: 0;
    z-index: 2000 !important;
}
li.select-item.vdo {
    z-index: 8000;
}