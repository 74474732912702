
@font-face {
    font-family: 'Snell Roundhand';
    src: url('../fonts/SnellRoundhand-BoldScript.woff2') format('woff2'),
        url('../fonts/SnellRoundhand-BoldScript.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Snell Roundhand';
    src: url('../fonts/SnellRoundhand-BlackScript.woff2') format('woff2'),
        url('../fonts/SnellRoundhand-BlackScript.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Snell';
    src: url('../fonts/SnellBT-Bold.woff2') format('woff2'),
        url('../fonts/SnellBT-Bold.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Snell';
    src: url('../fonts/SnellBT-Regular.woff2') format('woff2'),
        url('../fonts/SnellBT-Regular.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}


@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Medium.woff2') format('woff2'),
        url('../fonts/Gotham-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}


@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-BookItalic.woff2') format('woff2'),
        url('../fonts/Gotham-BookItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Medium.woff2') format('woff2'),
        url('../fonts/Gotham-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham light';
    src: url('../fonts/Gotham-Light.woff2') format('woff2'),
        url('../fonts/Gotham-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Bold.woff2') format('woff2'),
        url('../fonts/Gotham-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Medium.woff2') format('woff2'),
        url('../fonts/Gotham-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-BookItalic.woff2') format('woff2'),
        url('../fonts/Gotham-BookItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-MediumItalic.woff2') format('woff2'),
        url('../fonts/Gotham-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Book.woff2') format('woff2'),
        url('../fonts/Gotham-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-LightItalic.woff2') format('woff2'),
        url('../fonts/Gotham-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-MediumItalic.woff2') format('woff2'),
        url('../fonts/Gotham-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Light.woff2') format('woff2'),
        url('../fonts/Gotham-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Bold.woff2') format('woff2'),
        url('../fonts/Gotham-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-LightItalic.woff2') format('woff2'),
        url('../fonts/Gotham-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Medium.woff2') format('woff2'),
        url('../fonts/Gotham-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-BoldItalic.woff2') format('woff2'),
        url('../fonts/Gotham-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Book.woff2') format('woff2'),
        url('../fonts/Gotham-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-BoldItalic.woff2') format('woff2'),
        url('../fonts/Gotham-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-BookItalic.woff2') format('woff2'),
        url('../fonts/Gotham-BookItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Black.woff2') format('woff2'),
        url('../fonts/Gotham-Black.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Bold.woff2') format('woff2'),
        url('../fonts/Gotham-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Bold.woff2') format('woff2'),
        url('../fonts/Gotham-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Thin.woff2') format('woff2'),
        url('../fonts/Gotham-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-XLight.woff2') format('woff2'),
        url('../fonts/Gotham-XLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-XLightItalic.woff2') format('woff2'),
        url('../fonts/Gotham-XLightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Thin.woff2') format('woff2'),
        url('../fonts/Gotham-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Black.woff2') format('woff2'),
        url('../fonts/Gotham-Black.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-UltraItalic.woff2') format('woff2'),
        url('../fonts/Gotham-UltraItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-BookItalic.woff2') format('woff2'),
        url('../fonts/Gotham-BookItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Light.woff2') format('woff2'),
        url('../fonts/Gotham-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Light.woff2') format('woff2'),
        url('../fonts/Gotham-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-ThinItalic.woff2') format('woff2'),
        url('../fonts/Gotham-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-UltraItalic.woff2') format('woff2'),
        url('../fonts/Gotham-UltraItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-XLight.woff2') format('woff2'),
        url('../fonts/Gotham-XLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-ThinItalic.woff2') format('woff2'),
        url('../fonts/Gotham-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-XLightItalic.woff2') format('woff2'),
        url('../fonts/Gotham-XLightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}



@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Bold.woff2') format('woff2'),
        url('../fonts/Montserrat-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-BoldItalic.woff2') format('woff2'),
        url('../fonts/Montserrat-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Medium.woff2') format('woff2'),
        url('../fonts/Montserrat-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-LightItalic.woff2') format('woff2'),
        url('../fonts/Lato-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Thin.woff2') format('woff2'),
        url('../fonts/Montserrat-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Lato Hairline';
    src: url('../fonts/Lato-Hairline.woff2') format('woff2'),
        url('../fonts/Lato-Hairline.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-BlackItalic.woff2') format('woff2'),
        url('../fonts/Lato-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Italic.woff2') format('woff2'),
        url('../fonts/Montserrat-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-ExtraLightItalic.woff2') format('woff2'),
        url('../fonts/Montserrat-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Bold.woff2') format('woff2'),
        url('../fonts/Lato-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-SemiBoldItalic.woff2') format('woff2'),
        url('../fonts/Montserrat-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-ThinItalic.woff2') format('woff2'),
        url('../fonts/Montserrat-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-BoldItalic.woff2') format('woff2'),
        url('../fonts/Lato-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-MediumItalic.woff2') format('woff2'),
        url('../fonts/Montserrat-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-LightItalic.woff2') format('woff2'),
        url('../fonts/Montserrat-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Lato Hairline';
    src: url('../fonts/Lato-HairlineItalic.woff2') format('woff2'),
        url('../fonts/Lato-HairlineItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Light.woff2') format('woff2'),
        url('../fonts/Montserrat-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Regular.woff2') format('woff2'),
        url('../fonts/Lato-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Italic.woff2') format('woff2'),
        url('../fonts/Lato-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Black.woff2') format('woff2'),
        url('../fonts/Lato-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Light.woff2') format('woff2'),
        url('../fonts/Lato-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-ExtraLight.woff2') format('woff2'),
        url('../fonts/Montserrat-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-SemiBold.woff2') format('woff2'),
        url('../fonts/Montserrat-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-ExtraBoldItalic.woff2') format('woff2'),
        url('../fonts/Montserrat-ExtraBoldItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-ExtraBold.woff2') format('woff2'),
        url('../fonts/Montserrat-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-BlackItalic.woff2') format('woff2'),
        url('../fonts/Montserrat-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Black.woff2') format('woff2'),
        url('../fonts/Montserrat-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Regular.woff2') format('woff2'),
        url('../fonts/Montserrat-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
