@import 'fontface';
@import 'variable';
@import 'reset';
@import 'mixins';
@import 'helper';
@import 'navbar';
@import 'slider';
@import 'buttons';
@import 'frontpage';
@import 'pageabout';
@import 'template';
@import 'social';
@import 'weather';
@import 'dropdownnavbar';
@import 'playvideo';
@import 'formlogin';
@import 'gallery';
@import 'preload';
$maincolor: #183441;
$white: #fdfdfd;
$black: #181818;
$font: #6b7980;
$otherfont: #c7c5c2;



