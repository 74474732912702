.perceptible-load {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  position: relative;
  margin: 0 auto;
//  border: 4px solid #aa8b52;
  background: $mainColor;
  width: 200px;
  height: 120px;
}

.perceptible-load .i1,
.perceptible-load .i2{
  position: absolute;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.perceptible-load .i2{
  right: 100%;
  /*animation-delay: 0.2s;*/
  -webkit-animation: perceptible 3.2s infinite linear;
          animation: perceptible 3.2s infinite linear;
}
.perceptible-load .i1:before,
.perceptible-load .i2:after{
  content: "";
  position: absolute;
  top:0;
  left: 0;
  width: 192px;
  height: 112px;
  background: url('../images/preload.png') no-repeat;
  background-size: 80% auto;
  background-position: center center;
  background-color: transparent;
  opacity: 0.3;
  /*animation-delay: 0.2s;*/
  -webkit-animation: swap 3.2s infinite linear;
          animation: swap 3.2s infinite linear;

}
.perceptible-load .i2:after{
  opacity: 1;
}

/*@-webkit-keyframes perceptible {
   0% {
    right: 100%;
  }
  50% {
    right: 0;
  }
  50.1%{
    left: 0;
    right: 0;
  }
  100%{
    left: 100%;
    right: 0;
  }
}
*/
@keyframes perceptible {
  0% {
    right: 100%;
  }
  50% {
    right: 0;
  }
  50.1%{
    left: 0;
    right: 0;
  }
  100%{
    left: 100%;
    right: 0;
  }
}
/*
*/
@keyframes swap {
  100%{
    left: auto;
    right: 0;
  }
}
@-webkit-keyframes swap {
  0%{
    left: auto;
    right: 0;
  }
  50%{
    left: 0;
    right: auto;
  }
  100%{
    left: auto;
    right: 0;
  }
}
div#loading {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99999999;
    background: $mainColor;
}