body {
    font-family: 'Gotham light';
}

p {
    font-size: 16px;
    line-height: 24px;
}

a {
    color: $mainColor;
}

ul,
li,
h1,
h2,
h3,
h4,
p,
a {

    margin: 0;
    padding: 0;
}


a:hover {
    text-decoration: none;
}

li {
    list-style: none;
}
