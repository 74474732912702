.register-form {

    $background: #462142;
    $background-highlight: #604055;
    $white: #fff;
    $highlight: #47b39c;



    .togglepass {
        .fa-eye-slash {
            position: absolute;
            opacity: 0 !important;
            color: #c3c3c3;
        }
        .fa-eye {
            opacity: 1 !important;
            position: absolute;
            color: #c3c3c3;
        }
        &.show {
            .fa-eye-slash {
                opacity: 1 !important;
                position: absolute;
                color: #c3c3c3;
            }
            .fa-eye {
                opacity: 0 !important;
                position: absolute;
                color: #c3c3c3;
            }
        }
    }

    /* Change Autocomplete styles in Chrome*/
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus input:-webkit-autofill,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border: 1px solid $secondColor;
        -webkit-text-fill-color: $secondColor;
        -webkit-box-shadow: 0 0 0 30px transparent inset;
        transition: background-color 5000s ease-in-out 0s;


    }
    label {
        display: block;
        margin: 0 !important;
        transition: all 200ms ease;
        i {
            display: block;
            width: 40px;
            height: 40px;

            color: #c3c3c3;
            position: absolute;
            text-align: center;
        }
    }

    .forgot {
        margin: 20px 0 !important;
        text-align: right;
    }


    .fa {
        font-size: 20px;
        line-height: 40px;
    }

    .sr-only {
        display: none;
    }

    .form-control {
        position: relative;
        margin-top: 20px;
    }


    input[type="password"] {
        background-image: url(../images/fa-lock.png) !important;
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        background-position: 0% center;
        background-size: 18px;
        &:focus {
            background-size: 18px;
            background-image: url(../images/fa-lock-active.png) !important;
        }
        &:visited {
            background-size: 18px;
            background-image: url(../images/fa-lock-active.png) !important;
        }
    }
    input[type="email"] {
        background-image: url(../images/fa-envelope.png) !important;
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        background-size: 18px;
        background-position: 0% center;
        &:focus {
            background-size: 18px;
            background-image: url(../images/fa-envelope-active.png) !important;
        }
        &:visited {
            background-size: 18px;
            background-image: url(../images/fa-envelope-active.png) !important;
        }
        /* Change Autocomplete styles in Chrome*/
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus input:-webkit-autofill,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
        select:-webkit-autofill,
        select:-webkit-autofill:hover,
        select:-webkit-autofill:focus {
            border: 1px solid $secondColor;
            -webkit-text-fill-color: $secondColor;
            -webkit-box-shadow: 0 0 0px 1000px #000 inset;
            transition: background-color 5000s ease-in-out 0s;


        }
    }

    input[type="password"],
    input[type="email"],
    input[type="text"] {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid #c3c3c3;
        border-top: 0px !important;
        border-right: 0px !important;
        border-left: 0px !important;

        box-shadow: none !important;
        padding: 0 10px 0 40px;
        font-size: 16px;
        &:focus {
            outline: none;
            border-bottom: 1px solid $secondColor;


        }
        &:visited {
            outline: none;
            border-bottom: 1px solid $secondColor;


        }
        &.password {
            padding: 0 40px;
        }
    }



    .togglepass {
        position: absolute;
        right: 10px;
        top: 0;
        background: none;
        border: 0;
        font-size: 15px;
        color: $white;
        width: 60px;
        padding: 5px 0;
        cursor: pointer;
        transition: all 200ms ease;
        &:focus {
            outline: none;
        }

    }

    p {
        color: $secondColor;
    }
    .password-shown {
        display: none;
    }

    .form-control {
        &._btn_log {

            padding: 0 !important;
            margin: 0 !important;
        }
    }
    .last-section {
        @media screen and (min-width: 992px) {
            margin-top: 80px;
        }
    }

    .forgot {
        a {
            color: $secondColor;
        }
    }

}
