._no_pd {
    padding: 0;
}

._no_mg {
    margin: 0;
}

._bold {
    font-weight: 600 !important;
}
._pd_0_5 {
    padding: 0 5px;
}
._mainColor {
    color: $mainColor;
}

.img-center {
    margin: 0 auto;
}

._upperCase {
    text-transform: uppercase;
}
@media only screen and (min-width: 992px) {
    ._no_pd_pc {
        padding: 0;
    }
}

.img-icon {
    max-width: 36px;
}

.outer-border {
    border: 1px solid #c3c3c3;
    margin: 100px 0;
    @media screen and (max-width: 991px)
    {
        margin: 200px 0;
    }
}

.border-r {
    border-right: 1px solid #c3c3c3;
    position: relative;
    @media screen and (max-width: 991px) {
        border: none !important;
    }
}

._ps_relative {
    position: relative !important;
}

.breadcrumb {
    @media screen and (max-width: 767px) {
        padding: 5px !important;
        text-align: left;

    }
    text-align: right;
}

._table_style {
    p {
        padding: 5px 10px;
    }
    .meeting-title {
        color: $secondColor;
        text-align: left !important;
    }
    padding-top: 10px;
    tbody {
        background: #edeae7;
    }
    thead {
        color: #fff;
        background: $secondColor;
    }
    margin-top: 10px;
    .table-striped > tbody > tr:nth-of-type(odd) {
        background-color: #f2f1ed;
    }
    td {
        vertical-align: middle !important;
    }
}
