#content {
    .facilities_list {
        a {
            color: #000;
        }
        .facility-card {
            &:hover {
                cursor: pointer;
                .descriptions {

                    @include transition();

                    &::before {
                        @include transition();
                        content: '';
                        border-bottom: 5px solid rgba(#b29f71, 1) !important;
                        position: absolute;
                        bottom: -1.5px;
                        left: 0;
                        right: 0;
                        width: 80%;
                        z-index: 9;
                        margin: 0 auto;
                    }
                    &::after {
                        @include transition();
                        content: '';
                        border-bottom: 1px solid rgba(#b29f71, 1) !important;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        width: 100%;
                        z-index: 9;
                        margin: 0 auto;
                    }
                }
                .cover {
                    outline: 0;
                    cursor: pointer;
                    img {
                        @include transition(0.6);
                        opacity: 1 !important;
                        z-index: 99;
                    }
                    &::after {
                        @include transition(0.6);
                        opacity: 1;
                    }
                }
            }
            background: #fff;
            position: relative;
            z-index: 2;
            .cover {
                &:hover {
                    outline: 0;
                    cursor: pointer;
                    img {
                        @include transition(0.6);
                        opacity: 1 !important;
                        z-index: 99;
                    }
                    &::after {
                        @include transition(0.6);
                        opacity: 1;
                    }
                }
                &::after {
                    @include transition();
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    background: rgba(#966e41, 0.8);
                    display: block;
                    opacity: 0;
                    z-index: 1;
                }
                position: relative;
                z-index: 9;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                height: 340px;
                @media only screen and (max-width: 1199px) {
                    height: 250px;
                }
                outline: 1px solid #a9976d;
                outline-offset: -20px;
                .title {
                    text-transform: uppercase;
                    img {
                        opacity: 0;
                        @include transition();
                        max-height: 50px;
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 35%;
                        @media only screen and (max-width: 1199px) {
                            top: 30%;
                        }
                        margin: 0 auto;
                    }
                    h3 {
                        font-weight: 100;
                        color: #fff;
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 50%;
                        bottom: 0;
                        text-align: center;
                        z-index: 9;
                    }
                }
            }
            .descriptions {
                @include transition();
                &:hover {
                    @include transition();
                    &::after {
                        @include transition();
                        content: '';
                        border-bottom: 1px solid rgba(#b29f71, 1) !important;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        width: 100%;
                        z-index: 9;
                        margin: 0 auto;
                    }
                    &::before {
                        @include transition();
                        content: '';
                        border-bottom: 5px solid rgba(#b29f71, 1) !important;
                        position: absolute;
                        bottom: -1.5px;
                        left: 0;
                        right: 0;
                        width: 80%;
                        z-index: 9;
                        margin: 0 auto;
                    }
                }
                padding: 60px 40px;
                text-align: center;
                .title {
                    h3 {
                        margin-bottom: 20px;
                        color: $secondColor;
                        font-weight: 400;
                    }
                }
                .date {
                    font-family: 'lato';
                    p {
                        padding: 20px 0;
                        position: relative;
                        top: 2px;
                        display: inline-block;
                        color: $mainColor;
                    }
                    img {
                        display: inline-block;
                        max-width: 20px;
                        margin: 5px 10px 10px 0;
                    }
                }
                .details {
                    @media only screen and (min-width: 1200px) {
                        width: 60%;
                    }
                    margin: 0 auto;
                }
            }
        }
    }
}

#footer {
    background: #231f20;
    .first_col {
        h2 {
            font-weight: 400;
            font-size: 20px;
            @media screen and (max-width: 767px) {
                font-size: 16px;
            }
            a {
                color: $secondColor;
                @include transition();
                &:hover {
                    color: $mainColor;
                    @include transition();
                }
            }
        }
        h3 {
            font-weight: 600;
            font-size: 20px;
            @media screen and (max-width: 767px) {
                font-size: 16px;
            }
            a {
                color: $secondColor;
                @include transition();
                &:hover {
                    color: $mainColor;
                    @include transition();
                }
            }
        }
        span {
            color: $secondColor;
        }
        background: #fff;
        text-transform: uppercase;
        border-top: 1px solid rgba(0, 0, 0, 0.07);
        padding: 20px 50px;
        &::after {
            content: '';
            border-top: 1px solid rgba(#b29f71, 1) !important;
            position: absolute;
            top: -2px;
            left: 0;
            width: 46%;
            z-index: 9;
        }
        &::before {
            content: '';
            border-top: 5px solid rgba(#b29f71, 1) !important;
            position: absolute;
            top: -4px;
            left: 50px;
            width: 40%;
            z-index: 9;
        }
        &.frontpage {
            &::before {
                display: none !important;
            }
            &::after {
                display: none !important;
            }
        }
    }
    .left_col {
        .business-group {
            .slideControls {
                @media screen and (max-width: 991px) {
                    display: none;
                }
                top: -50px;
                position: relative;
                .slidePrev {
                    position: absolute;
                    left: -30px;
                }
                .slideNext {
                    position: absolute;
                    right: -30px;
                }
                span {
                    color: #fff;
                }
            }

            .lSSlideOuter .lSPager.lSpg > li.active a,
            .lSSlideOuter .lSPager.lSpg > li:hover a {
                background-color: #ffffff;
            }
            .lSSlideOuter .lSPager.lSpg > li a {
                background-color: #9f9f9f;
            }

            @media only screen and (max-width: 767px) {
                padding: 20px 0 80px !important;

            }
            @media only screen and (max-width: 991px) {
                padding: 20px 0 40px;
                img {
                    margin: 0 auto;
                    padding: 20px;
                }
            }
            padding: 30px 70px;
            @media screen and (min-width: 1200px) and (max-width: 1600px) {
                padding: 50px 0;
            }
            background: #231f20;
            .details {
                padding: 50px 70px;
                @media screen and (max-width: 991px) {
                    padding: 0 60px !important;
                }
            }
            .subscribe-form {
                @media screen and (max-width: 1199px) {
                    padding: 0 0 50px 0;
                }
                ::-webkit-input-placeholder {
                    /* Chrome/Opera/Safari */
                    color: white;
                }
                ::-moz-placeholder {
                    /* Firefox 19+ */
                    color: white;
                }
                :-ms-input-placeholder {
                    /* IE 10+ */
                    color: white;
                }
                :-moz-placeholder {
                    /* Firefox 18- */
                    color: white;
                }
                .title {

                    text-align: right;
                    @media only screen and (max-width: 991px) {
                        text-align: center;
                        font-size: 12px !important;
                    }
                }
                p {
                    padding: 11px 0;
                    color: #fff;
                    text-transform: uppercase;
                }
                .social-icon {
                    text-align: left !important;
                    @media only screen and (max-width: 991px) {
                        text-align: center !important;
                    }
                }
                .fa-facebook-square {
                                       &:hover {


                        position: relative;
                        z-index: 99;
                        cursor: pointer;
                        @include transition();
                        color: $mainColor;

                        &::after {
                            width: 26px;
                            @include transition();
                            border-radius: 5px;
                            z-index: 2;
                            top: 7px;
                            left: 5px;
                            background: #fff;
                            content: '';
                            display: block;
                            position: absolute;
                            height: 31px;
                        }

                        &::before {
                            position: relative;
                            z-index: 9 !important;
                        }

                    }
                }
                i {
                    margin:0 6px;
                    font-size: 42px;
                    color: #fff;

                    position: relative;
                    z-index: 5;
                    @include transition();
                    
 
                    @media only screen and (max-width: 767px) {
                        margin: 20px 0;
                    }

                }
                .input-group {
                    @media only screen and (max-width: 991px) {
                        padding: 0 20px;
                    }
                }
                input.form-control {
                    border: 1px solid rgba(204, 204, 204, 0.5);
                    border-radius: 0px;
                    background: 0;
                    padding: 20px;
                    color: #fff;
                    @media screen and (max-width: 991px) {
                        font-size: 12px !important;
                        padding: 20px 10px;
                        text-align: center;
                    }
                }
            }
        }
    }
    .right_col {
        border-top: 1px solid rgba(0, 0, 0, 0.07);
        background: #fff;
        .footer_logo {
            padding: 40px;
            @media only screen and (max-width: 767px) {
                padding: 20px 40px 0;
                img {
                    margin: 0 auto;
                }
            }
        }
        .address {
            h1 {
                font-size: 24px;
                text-transform: uppercase;
                font-weight: 400;
                color: $secondColor;
                margin: 15px 0;
            }
            h2 {
                text-transform: uppercase;
                padding-top: 20px;
                margin-top: 20px;
                font-size: 14px;
                font-weight: 400;
            }
            p {
                font-size: 12px;
                font-family: 'Montserrat';
            }
            .copyright {
                padding: 10px 0;
                @media only screen and (max-width: 767px) {
                    text-align: center;
                }
            }
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1300px) {
    .main_menu .navbar-default .navbar-brand img {
        max-width: 130px;

    }
    .main_menu .navbar-default .navbar-nav li a {

        padding: 15px 10px;
        font-size: 11px;
    }
}
