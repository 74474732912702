ul.dropdown-menu {
    background: rgba(#976e40, 0.74);
    a {
        padding: 10px !important;
    }
    .navbar-nav .active a {
        color: #313131!important;
        background: 0!important;
    }


}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover {
    color: $secondColor;
    background: 0;

}


.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
    color: #fff !important;
    text-decoration: none;
    background: 0;
}

.dropdown-menu {
    li {
        a {

            border-bottom: 1px solid rgba(white, 0.3);
        }

        &:last-child {
            a {
                border: none !important;
            }
        }

    }




}

li#menu-item-285.current_page_parent a {
    color: $secondColor;
}

li#menu-item-416.current_page_item a,
li#menu-item-286.current_page_item a,
li#menu-item-287.current_page_item a,
li#menu-item-684.current_page_item a {
    color: #fff !important;
}

.dropdown-menu > li > a {
    color: #000 !important;
    text-decoration: none;
    background: 0;
}


.current_page_item {
    a {
        color: $secondColor !important;
    }

}

.navbar-nav > li > .dropdown-menu {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-radius: 0 !important;
}
