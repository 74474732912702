@mixin button-bg($bg) {
    background: $bg;
    border: 1px solid $bg;
    &:hover,
    &:focus {
        background: darken($bg, 8%);
        border: 1px solid $bg;
        @include transition(0.4);
    }
    &:active {
        background: darken($bg, 25%);
        border: 1px solid $bg;
    }
}

%_btnDefault {
    color: #fff;
    width: 100%;
    border: 1px solid;
    padding: 20px;
    margin-top: 30px;
    text-align: center;
    cursor: pointer;
}

._btn_login {
    margin: 0 auto;
    padding: 10px 20px;
    @extend %_btnDefault;
    @include button-bg($secondColor);
    @media screen and (max-width: 991px) {
        margin: 30px 0;

    }
}

._btn_download {

    p {
        font-size: 14px !important;
    }
    padding: 10px;
    @extend %_btnDefault;
    @include button-bg($secondColor);
    border-color: rgba($secondColor, 0.4);
    &:hover {
        border-color: rgba(150, 110, 65, .4);
        background: #fff;
        color: $mainColor;

    }

}

._btn_direction {
    p {
        font-size: 14px !important;
    }
    padding: 10px;
    color: #000;
    @extend %_btnDefault;
    @include button-bg(#fff);
    border-color: rgba($secondColor, 0.4);
    @media screen and (max-width: 767px) {
        margin-top: 10px;
    }
    &:hover {
        border-color: rgba(150, 110, 65, .4);
        background: $secondColor;
        color: #fff;
    }
}

.btn_style_3 {
    @extend %_btnDefault;
    @include button-bg($secondColor);
    display: inline;
    width: inherit!important;
    padding: 10px 15px;
    &.is-checked {
        background: $mainColor;
    }
    margin: -1px 0 !important;
}

._btn_back {
    position: relative;
    z-index: 9999;
    display: inline-block;
    width: 100px;
    margin-top: 33px !important;
    p {
        text-transform: uppercase !important;
        text-align: center !important;
        font-size: 14px !important;
    }
    padding: 10px;
    color: #000;
    border-color: #eee7e6 !important;
    @extend %_btnDefault;
    @include button-bg(#fff);
    border-color: rgba($secondColor, 0.4);
    @media screen and (max-width: 767px) {
        margin-top: 10px;
    }
    &:hover {
        border-color: #eee7e6;
        background: $secondColor;
        color: #fff;
    }
}

._btn_submit {
    margin: 0;
    padding: 10px;
    border-radius: 0;
    @extend %_btnDefault;
    @include button-bg($secondColor);
    border-color: rgba($secondColor, 0.4);
    &:hover {
        color: #fff;
    }
}


