.flexslider {

    overflow: hidden;
    margin: 0;
    border: none;
    position: relative;
    z-index: 1;
    .flex-control-nav {

        bottom: 30px;
        z-index: 9;
    }
    .flex-control-paging {
        @media only screen and (max-width: 991px) {
            display: none;
        }
        li {
            a.flex-active {
                background: #fff !important;
                opacity: 1;
            }
            a {
                background: #bbb !important;
                opacity: 0.5;
            }
        }
    }
    .flex-prev {
        z-index: 99;
        left: 10px !important;
        opacity: 1 !important;
        &:before {
            content: "\e875" !important;
            font-family: Linearicons-Free;
            speak: none;
            font-style: normal;
            font-weight: 400;
            color: white !important;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            background: none !important;
            font-size: 20px;
            padding: 10px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }
    .flex-next {
        z-index: 99;
        right: 10px !important;
        text-align: right;
        opacity: 1 !important;
        &:before {
            background: none;
            font-size: 20px;
            padding: 10px;
            content: "\e876" !important;
            font-family: Linearicons-Free;
            speak: none;
            color: white !important;
            font-style: normal;
            font-weight: 400;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }
    .slide_caption {
        position: absolute;
        top: 40%;
        bottom: 0;
        text-align: left;
        width: 20%;
        right: 10%;
        z-index: 2;
        position: absolute;
        @media (min-width: 992px) and (max-width:1400px) {
            top: 40%;
            bottom: 0;
            text-align: center;
            width: 70%;
            right: 0;
            z-index: 2;
            left: 0;
            margin: 0 auto;
        }

        @media only screen and (max-width: 991px) {
            position: relative;
            top: 0;
            bottom: 0;
            width: 100%;
            right: 0;
            text-align: center;
            padding: 30px 40px 30px 40px;
            z-index: 9;
            background: #fff;
            p {
                color: #000 !important;

            }
            h1 {
                color: $mainColor !important;
            }
            .description {
                p {
                    line-height: 50px;
                    text-transform: uppercase;
                    font-weight: 400;
                    display: block;
                    color: #000 !important;
                }
                span {
                    display: block;
                    line-height: 42px;
                    text-transform: uppercase;
                    font-weight: 400;
                    display: block;
                    color: #000 !important;
                }
            }

        }
 
        .description {
            p {
                line-height: 50px;
                font-size: 30px;
                color: #fff;
                text-transform: uppercase;
            }
            span {
                display: block;
                line-height: 42px;
                font-size: 30px;
                color: #fff;
                text-transform: uppercase;
            }
        }
    }
}
div#captions {
    color: #fff;
}
@media screen and (max-width: 767px) {
    .flexslider {
        margin: 0;
        border: none;
        .flex-direction-nav {
            a {
                top: 43% !important;
            }
        }
        .flex-prev {
            left: 0 !important;
            margin: 0 !important;
        }
        .flex-next {
            right: 0 !important;
            margin: 0 !important;
        }
    }
}


@media screen and (max-width: 991px) {
    .flexslider {

        .flex-direction-nav {
            a {
                top: 30% !important;
            }
        }

    }
}

@media screen and (max-width: 480px) {
    .flexslider {

        .flex-direction-nav {
            a {
                top: 20% !important;
            }
        }

    }
}


.flex-direction-nav {
    position: relative !important;
    top: -55% !important;
    @media screen and (max-width: 767px) {
        top: -70% !important;
    }

}

li.flex-nav-prev {
    position: absolute !important;
    left: 0 !important;
}

li.flex-nav-next {
    position: absolute !important;
    right: 0 !important;
}

div#captions {
    background: 0;
    border: 0;
    box-shadow: none;
}
div#carousel {
    padding-bottom: 30px;
    .flex-control-nav {
        bottom: 0 !important;
    } 
    .flex-direction-nav{
        display: none;
    }
}