@mixin transition($time: 0.2) {
    -moz-transition: all $time + s ease-in-out;
    -o-transition: all $time + s ease-in-out;
    -webkit-transition: all $time + s ease-in-out;
    transition: all $time + s ease-in-out;
}

@mixin fontSize($size: 16) {
    font-size: $size + px;
    line-height: ($size+16) + px;
}

@mixin borderRadius($radius: 10) {
    border-radius: $radius + px;
    -webkit-border-radius: $radius + px;
    -moz-border-radius: $radius + px;
}

@mixin bgColor ($color:black, $opacity:0.5) {
    background-color: rgba($color, $opacity);
}

@mixin bdColor ($color:black, $opacity:0.5) {
    border-color: rgba($color, $opacity);
}

